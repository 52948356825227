import {formatTime,toNextTimes} from '@/utils/utils'
import {getWeatherInfo} from '@/api/axios'

class TopHeader {

    state = {
        current_date: "",
        current_time: "",
        updated_date: "",
        address: '广东省.广州市',
        weatherInfo: {
            wea_img: 'qing',
            tem: '30',
            wea: '晴天'
        },
        modulePage: 0
    }

    mutations = {

        // 获取当前的时间
        GET_CURRENT_TIME: state => {
            const [year, month, day, hour, minute, second] = formatTime(new Date, 3);
            state.current_date = `${year}年${month}月${day}日`;
            state.current_time = `${hour}:${minute}:${second}`;
            state.updated_date = formatTime(new Date(toNextTimes(-1)), 5);
        },


        // 保存当前城市天气信息
        SAVE_WEATHER_INFO: (state, payload) => {
            state.weatherInfo = payload;
        },


        SWITCH_TYPE: (state, payload) => {
            state.modulePage = payload;

        },


    }

    actions = {

        // 获取当前城市天气信息
        GET_WEATHER_INFO: async ({commit}, params) => {
            const {data} = await getWeatherInfo({city:params});
            commit("SAVE_WEATHER_INFO", data)
        },

    }

}

export default new TopHeader
