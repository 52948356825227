import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import countTo from 'vue-count-to'
import {scrollBoard} from '@jiaminghi/data-view'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss'
import fullScreenContainer from "./components/dv-full-screen/dv-full-screen"
import sectionTitleBar from "./views/home/components/section-title-bar"
import './directive/load-more'

import * as api from './api/index'
import * as currency from './utils/utils'
import Auth from "./model/auth"

Vue.use(ElementUI)
Vue.use(scrollBoard)
Vue.component('dvFullScreenContainer', fullScreenContainer)
Vue.component('countTo', countTo);
Vue.component('SSectionTitleBar', sectionTitleBar);

Vue.prototype.$api = api
Vue.prototype.$currency = currency
Vue.prototype.$auth = Auth
Vue.prototype.$theme = '#0A5FEC'
Vue.prototype.$OSSURL = currency.ossFileURL

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
