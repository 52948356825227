const routes = [

    {
        path: "/404",
        component: () => import("@/views/404"),
    },

    {
        path: "*",
        redirect: "/404",
    },

    {
        path: '/',
        name: 'home',
        component: () => import('@/views/home/home')
    },

    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/home')
    },

    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/login"),
    },

]

export default routes
