import {API_BASE_URL} from "@/config/config";
import axios from './axios'

//登录
export const  signInUser = params => axios.post('auth/signInUser',params)
//退出登录
export const  loginOut = params => axios.get('auth/loginOut',{params})
//获取用户权限
export const  getAdminUserPermissionList = params => axios.get('auth/getAdminUserPermissionList',{params})
//免密登录
export const autoSignInUser = params => axios.post("auth/autoSignInUser", params)




/* 数据概览 */

//数据概况
export const dataOverview = params => axios.get("data/dataOverview", {params});
//膳食服务
export const foodService = params => axios.get("data/foodService", {params});
//饭卡充值数据
export const getCardRecharge = params => axios.get("data/getCardRecharge", {params});
//内部人员数据
export const getStaffInfoData = params => axios.get("data/getStaffInfoData", {params});
//今天总人数前10的单位
export const getUnitUserTotalTenth = params => axios.get("data/getUnitUserTotalTenth", {params});
//基础数据
export const getBasisData = params => axios.get("data/getBasisData", {params});
//预约访客
export const getVisitAppointmentData = params => axios.get("data/getVisitAppointmentData", {params});
//会议室预约
export const getResourceAppointmentData = params => axios.get("data/getResourceAppointmentData", {params});
//物业服务
export const getRepairData = params => axios.get("data/getRepairData", {params});
//班车服务
export const getBusData = params => axios.get("data/getBusData", {params});
//公务用车
export const getOfficialCarData = params => axios.get("data/getOfficialCarData", {params});




/* 会议室预约 */

//数据概况
export const resourceDataOverview = params => axios.get("data/resourceDataOverview", {params});
//实时预约动态
export const resourceRealTimeDynamic = params => axios.get("data/resourceRealTimeDynamic", {params});
//近期预约情况
export const resourceSituation = params => axios.get("data/resourceSituation", {params});
//近期热门会议室前10
export const resourceHotTenth = params => axios.get("data/resourceHotTenth", {params});
//未来五天的使用率、空置率
export const resourceUsageRate = params => axios.get("data/resourceUsageRate", {params});





/* 访客预约 */

//数据概况
export const visitDataOverview = params => axios.get("data/visitDataOverview", {params});
//实时预约动态
export const visitRealTimeDynamic = params => axios.get("data/visitRealTimeDynamic", {params});
// 近期预约情况
export const visitSituation = params => axios.get("data/visitSituation", {params});
// 近期被访单位前十
export const visitUnitTenth = params => axios.get("data/visitUnitTenth", {params});
// 获取所有园区
export const getAllPark = params => axios.get("data/getAllPark", {params});
// 近期来访热力图
export const visitHeatMap = params => axios.get("data/visitHeatMap", {params});
// 访客预约轮询
export const visitPolling = params => axios.get("data/visitPolling", {params});
// 会议室预约轮询
export const resourcePolling = params => axios.get("data/resourcePolling", {params});
