import {
    visitDataOverview,
    visitRealTimeDynamic,
    visitHeatMap,
    visitSituation,
    visitUnitTenth, visitPolling,
} from "@/api";


export default {

    state: {

        // 数据概况
        overviewData: [
            {old_count: 0, count: 0, suffix: '', name: '累计预约次数'},
            {old_count: 0, count: 0, suffix: '', name: '累计访客人次'},
            {old_count: 0, count: 0, suffix: '', name: '开放预约单位'},
            {old_count: 0, count: 0, suffix: '', name: '审核人员'},
            {old_count: 0, count: 0, suffix: '', name: '平均审核时长/分'},
            {old_count: 0, count: 0, suffix: '%', name: '一审通过率'},
            {old_count: 0, count: 0, suffix: '%', name: '二审通过率'},
            {old_count: 0, count: 0, suffix: '', name: '日均来访人次'},
        ],

        visitRealTimeDynamicList: [],
        visitRealTimeDynamicTotal: 0,
        visitRealTimeDynamicLoading: false,

        // 近期预约情况
        visitSituation: {},
        visitSituationTimeType: 1,

        // 近期被访问单位前10
        visitUnitTenth: [],
        visitUnitTenthParams:{ time_type: 1, type: 1 },

        // 近期来访热力图
        visitHeatMap: [],
        visitHeatMapParams:{ time_type: 1, park_id: '' },

    },

    mutations: {

        setVisitOverviewData(state, data) {
            state.overviewData[0].old_count = state.overviewData[0].count
            state.overviewData[1].old_count = state.overviewData[1].count
            state.overviewData[2].old_count = state.overviewData[2].count
            state.overviewData[3].old_count = state.overviewData[3].count
            state.overviewData[4].old_count = state.overviewData[4].count
            state.overviewData[5].old_count = state.overviewData[5].count
            state.overviewData[6].old_count = state.overviewData[6].count
            state.overviewData[7].old_count = state.overviewData[7].count

            state.overviewData[0].count = data['visit_count'] || 0
            state.overviewData[1].count = data['visit_person_time_count'] || 0
            state.overviewData[2].count = data['open_visit_unit_count'] || 0
            state.overviewData[3].count = data['visit_reviewer_count'] || 0
            state.overviewData[4].count = data['avg_review_duration'] || 0
            state.overviewData[5].count = parseFloat(data['first_review_pass_rate']) || 0
            state.overviewData[6].count = parseFloat(data['second_review_pass_rate']) || 0
            state.overviewData[7].count = data['avg_daily_visitor_number'] || 0
        },


        setVisitRealTimeDynamic(state, {data, page_info}) {
            state.visitRealTimeDynamicList = data
            state.visitRealTimeDynamicTotal = page_info.total
        },
        setVisitRealTimeDynamicLoading(state, bool) {
            state.visitRealTimeDynamicLoading = bool
        },


        setVisitSituation(state, date) {
            state.visitSituation = date
        },
        setVisitSituationTimeType(state, type){
          state.visitSituationTimeType = type
        },

        setVisitUnitTenth(state, data) {
            state.visitUnitTenth = data
        },
        setVisitUnitTenthParams(state, data){
            state.visitUnitTenthParams = data
        },

        setVisitHeatMap(state, data) {
            state.visitHeatMap = data
        },
        setVisitHeatMapParams(state, data){
            state.visitHeatMapParams = data
        },

    },

    actions: {

        // 数据概况
        async getVisitOverviewData({commit}) {
            const {data, level} = await visitDataOverview()
            level === 'success' && commit('setVisitOverviewData', data)
        },


        // 实时预约动态
        async getVisitRealTimeDynamic({commit}, params = {page: 1, limit: 12}) {
            commit('setVisitRealTimeDynamicLoading', true)
            const {data, level, page_info} = await visitRealTimeDynamic(params)
            level === 'success' && commit('setVisitRealTimeDynamic', {data, page_info})
            commit('setVisitRealTimeDynamicLoading', false)
        },


        // 近期预约情况
        async getVisitSituation({commit,state}) {
            const time_type = state.visitSituationTimeType
            const {data, level} = await visitSituation({time_type})
            level === 'success' && commit('setVisitSituation', data)
        },

        // 近期被访问单位前10
        async getVisitUnitTenth({commit, state}) {
            const params = state.visitUnitTenthParams
            const {data, level} = await visitUnitTenth(params)
            level === 'success' && commit('setVisitUnitTenth', data)
        },

        // 近期来访热力图
        async getVisitHeatMap({commit, state}) {
            const params = state.visitHeatMapParams
            const {data, level} = await visitHeatMap(params)
            level === 'success' && commit('setVisitHeatMap', data)
        },

        // 轮询数据是否有更新
        async eventLoopVisitorUpdate({dispatch}) {
            const {data, level} = await visitPolling()
            if (level === 'success' && data === '1') {
                dispatch('getVisitOverviewData')
                dispatch('getVisitRealTimeDynamic')
                dispatch('getVisitSituation')
                dispatch('getVisitUnitTenth')
                dispatch('getVisitHeatMap')
            }
        }

    }

}
