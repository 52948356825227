import Vue from 'vue'
import Vuex from 'vuex'

import getters from "./getters"
import user from './modules/user'
import topHeader from './modules/header'
import mainly from './modules/mainly'
import visitor from './modules/visitor'
import conference from './modules/conference'


Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    user,               // 用户信息相关

    topHeader,          // 大屏顶部信息功能栏

    mainly,             // 数据概览模块

    conference,         // 会议室预约模块

    visitor,            // 访客预约模块
  },

  getters,

  state: {},
  mutations: {},
  actions: {},

})
