import Vue from 'vue'
import {debounce} from '@/utils/utils'

const loadMore = {

  bind(el, binding) {
    const selectWrap = el.querySelector('.el-table__body-wrapper')
    selectWrap.addEventListener('scroll', debounce(function() {
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
      if (scrollDistance <= 1) binding.value()
    }, 300))
  }

}

export default Vue.directive('loadMore', loadMore)
