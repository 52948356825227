import {
    dataOverview,
    foodService,
    getCardRecharge,
    getStaffInfoData,
    getUnitUserTotalTenth,
    getBasisData,
    getVisitAppointmentData,
    getResourceAppointmentData,
    getOfficialCarData,
    getRepairData,
    getBusData,
} from "@/api"

export default {

    state: {

        // 数据概况
        dataGeneral: [
            {old_count: 0, count: 0, name: '访客预约次数'},
            {old_count: 0, count: 0, name: '访客来访人次'},
            {old_count: 0, count: 0, name: '会议室预约次数'},
            {old_count: 0, count: 0, name: '会议室数量'},
            {old_count: 0, count: 0, name: '饭堂数量'},
            {old_count: 0, count: 0, name: '饭卡充值记录'},
            {old_count: 0, count: 0, name: '物业服务工单'},
            {old_count: 0, count: 0, name: '物业服务人员'},
        ],

        canteenList: [],
        canteenLoading: false,

        cardRechargeList: [],
        cardRechargeType: 1,
        cardRechargeLoading: false,

        staffInfoData: {},
        staffInfoLoading: false,

        unitUserTotal: [],
        unitUserLoading: false,

        basisData: {},

        visitAppointmentData: {},
        visitAppointmentType: 1,
        visitAppointmentLoading: false,

        appointmentRoomList: [],
        appointmentRoomType: 1,
        appointmentRoomLoading: false,

        propertyServiceData: {},
        propertyServiceType: 1,
        propertyServiceLoading: false,

        busData: {},
        busType: 1,
        busLoading: false,

        officialCarList: {},
        officialCarType: 1,
        officialCarLoading: false,

    },

    mutations: {

        setDataGeneral: (state, data) => {
            state.dataGeneral[0].old_count = state.dataGeneral[0].count
            state.dataGeneral[1].old_count = state.dataGeneral[1].count
            state.dataGeneral[2].old_count = state.dataGeneral[2].count
            state.dataGeneral[3].old_count = state.dataGeneral[3].count
            state.dataGeneral[4].old_count = state.dataGeneral[4].count
            state.dataGeneral[5].old_count = state.dataGeneral[5].count
            state.dataGeneral[6].old_count = state.dataGeneral[6].count
            state.dataGeneral[7].old_count = state.dataGeneral[7].count

            state.dataGeneral[0].count = data['visit_count'] || 0
            state.dataGeneral[1].count = data['visit_person_time_count'] || 0
            state.dataGeneral[2].count = data['resource_appointment_count'] || 0
            state.dataGeneral[3].count = data['resource_count'] || 0
            state.dataGeneral[4].count = data['canteen_count'] || 0
            state.dataGeneral[5].count = data['canteen_card_recharge_count'] || 0
            state.dataGeneral[6].count = data['repair_count'] || 0
            state.dataGeneral[7].count = data['repair_server_count'] || 0
        },


        setCanteenList: (state, list) => {
            const arr = []
            for (const item of list) {
                const {name, canteen_card_recharge_count, dish_count, canteen_card_recharge_amount, order_count} = item
                arr.push([name, canteen_card_recharge_count, canteen_card_recharge_amount, order_count, dish_count])
            }
            state.canteenList = arr
        },
        setCanteenLoading: (state, bool) => {
            state.canteenLoading = bool
        },


        setCardRechargeList: (state, list) => {
            state.cardRechargeList = list
        },
        setCardRechargeType: (state, type) => {
            state.cardRechargeType = type
        },
        setCardRechargeLoading: (state, bool) => {
            state.cardRechargeLoading = bool
        },


        setStaffInfoData: (state, data) => {
            state.staffInfoData = data
        },
        setStaffInfoLoading: (state, data) => {
            state.staffInfoLoading = data
        },


        setUnitUserTotal: (state, list) => {
            state.unitUserTotal = list
        },
        setUnitUserLoading: (state, bool) => {
            state.unitUserLoading = bool
        },


        setBasisData: (state, data) => {
            state.basisData = data
        },


        setVisitAppointmentData: (state, data) => {
            state.visitAppointmentData = data
        },
        setVisitAppointmentType: (state, type) => {
            state.visitAppointmentType = type
        },
        setVisitAppointmentLoading: (state, bool) => {
            state.visitAppointmentLoading = bool
        },


        setResourceAppointmentData: (state, list) => {
            state.appointmentRoomList = list
        },
        setResourceAppointmentType: (state, type) => {
            state.appointmentRoomType = type
        },
        setResourceAppointmentLoading: (state, bool) => {
            state.appointmentRoomLoading = bool
        },


        setOfficialCarData: (state, list) => {
            state.officialCarList = list
        },
        setOfficialCarType: (state, type) => {
            state.officialCarType = type
        },
        setOfficialCarLoading: (state, bool) => {
            state.officialCarLoading = bool
        },


        setPropertyServiceData: (state, data) => {
            state.propertyServiceData = data
        },
        setPropertyServiceType: (state, type) => {
            state.propertyServiceType = type
        },
        setPropertyServiceLoading: (state, bool) => {
            state.propertyServiceLoading = bool
        },


        setBusData: (state, data) => {
            state.busData = data
        },
        setBusType: (state, type) => {
            state.busType = type
        },
        setBusLoading: (state, bool) => {
            state.busLoading = bool
        }

    },

    actions: {

        // 获取数据概况
        getDataGeneral: async ({commit}) => {
            const {data, level} = await dataOverview()
            level === 'success' && commit('setDataGeneral', data)
        },


        // 获取膳食服务数据
        getCanteenList: async ({commit}) => {
            commit('setCanteenLoading', true)
            const {data, level} = await foodService()
            level === 'success' && commit('setCanteenList', data)
            commit('setCanteenLoading', false)
        },


        // 获取饭卡充值数据
        getCardRecharge: async ({commit, state}) => {
            commit('setCardRechargeLoading', true)
            const {data, level} = await getCardRecharge({time_type: state.cardRechargeType})
            level === 'success' && commit('setCardRechargeList', data)
            commit('setCardRechargeLoading', false)
        },


        // 获取内部人员数据
        getStaffData: async ({commit}) => {
            commit('setStaffInfoLoading', true)
            const {data, level} = await getStaffInfoData()
            level === 'success' && commit('setStaffInfoData', data)
            commit('setStaffInfoLoading', false)
        },


        // 总人数前10的单位
        getUnitUserTotalTenth: async ({commit}) => {
            commit('setUnitUserLoading', true)
            const {data, level} = await getUnitUserTotalTenth()
            level === 'success' && commit('setUnitUserTotal', data)
            commit('setUnitUserLoading', false)
        },


        // 基础数据
        getBasisData: async ({commit}) => {
            const {data, level} = await getBasisData()
            level === 'success' && commit('setBasisData', data)
        },


        // 预约访客
        getVisitAppointmentData: async ({commit}, type = 0) => {
            commit('setVisitAppointmentLoading', true)
            const {data, level} = await getVisitAppointmentData({time_type: type})
            level === 'success' && commit('setVisitAppointmentData', data)
            commit('setVisitAppointmentLoading', false)
        },


        // 会议室预约
        getResourceAppointmentData: async ({commit}, type = 0) => {
            commit('setResourceAppointmentLoading', true)
            const {data, level} = await getResourceAppointmentData({time_type: type})
            level === 'success' && commit('setResourceAppointmentData', data)
            commit('setResourceAppointmentLoading', false)
        },


        // 物业服务
        getRepairData: async ({commit}, type = 0) => {
            commit('setPropertyServiceLoading', true)
            const {data, level} = await getRepairData({time_type: type})
            level === 'success' && commit('setPropertyServiceData', data)
            commit('setPropertyServiceLoading', false)
        },


        // 班车服务
        getBusData: async ({commit}, type = 0) => {
            commit('setBusLoading', true)
            const {data, level} = await getBusData({time_type: type})
            level === 'success' && commit('setBusData', data)
            commit('setBusLoading', false)
        },


        // 公务用车
        getOfficialCarData: async ({commit}, time_type=0) => {
            commit('setOfficialCarLoading', true)
            const {data, level} = await getOfficialCarData({time_type: time_type})
            level === 'success' && commit('setOfficialCarData', data)
            commit('setOfficialCarLoading', false)
        },

    }

}
