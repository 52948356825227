import {
    resourceDataOverview,
    resourceHotTenth,
    resourceRealTimeDynamic,
    resourceSituation,
    resourceUsageRate,
    resourcePolling
} from '@/api'

export default {

    state: {

        resourceData: [
            {old_count: 0, count: 0, name: '累计预约次数'},
            {old_count: 0, count: 0, name: '开放预约会议室/间'},
            {old_count: 0, count: 0, name: '管理人员'},
            {old_count: 0, count: 0, name: '服务人员'},
            // {old_count: 0, count: 0, name: '平均审核时长/分'},
            // {old_count: 0, count: 0, name: '审核通过率'},
            {old_count: 0, count: 0, name: '日均预约单'},
            {old_count: 0, count: 0, name: '园区数量'},
            {old_count: 0, count: 0, name: '楼栋数量'},
        ],

        realTimeDynamicList: [],
        realTimeDynamicTotal: 0,
        realTimeDynamicLoading: false,

        resourceSituationData: {},
        resourceSituationType: 1,

        resourceHotTenthList: [],
        resourceHotTenthType: 1,

        resourceUsageRateList: [],

    },


    mutations: {

        setResourceData(state, data) {
            state.resourceData[0].old_count = state.resourceData[0].count
            state.resourceData[1].old_count = state.resourceData[1].count
            state.resourceData[2].old_count = state.resourceData[2].count
            state.resourceData[3].old_count = state.resourceData[3].count
            state.resourceData[4].old_count = state.resourceData[4].count
            state.resourceData[5].old_count = state.resourceData[5].count
            state.resourceData[6].old_count = state.resourceData[6].count
            // state.resourceData[7].old_count = state.resourceData[7].count

            state.resourceData[0].count = data['resource_appointment_count'] || 0
            state.resourceData[1].count = data['resource_count'] || 0
            state.resourceData[2].count = data['resource_appointment_reviewer_count'] || 0
            state.resourceData[3].count = data['resource_appointment_server_count'] || 0
            // state.resourceData[4].count = 0
            state.resourceData[4].count = data['average_daily_record'] || 0
            state.resourceData[5].count = data['park_count'] || 0
            state.resourceData[6].count = data['floor_count'] || 0
        },


        setRealTimeDynamic(state, {data, total}) {
            state.realTimeDynamicList = data
            state.realTimeDynamicTotal = total
        },
        setRealTimeDynamicLoading(state, bool) {
            state.realTimeDynamicLoading = bool
        },


        setResourceSituation(state, data) {
            state.resourceSituationData = data
        },
        setResourceSituationType(state, type) {
            state.resourceSituationType = type
        },


        setResourceHotTenth(state, list) {
            state.resourceHotTenthList = list
        },
        setResourceHotTenthType(state, type) {
            state.resourceHotTenthType = type
        },

        setResourceUsageRate(state, list) {
            state.resourceUsageRateList = list
        },

    },


    actions: {

        // 数据概况
        async getResourceData({commit}) {
            const {data, level} = await resourceDataOverview()
            level === 'success' && commit('setResourceData', data)
        },


        // 实时预约动态
        async getRealTimeDynamic({commit}, params) {
            params = params || {page: 1, limit: 20}
            commit('setRealTimeDynamicLoading', true)
            const {data, level, page_info} = await resourceRealTimeDynamic(params)
            if (level === 'success') {
                const {total} = page_info
                commit('setRealTimeDynamic', {data, total})
                commit('setRealTimeDynamicLoading', false)
            }
        },


        // 近期预约情况
        async getResourceSituation({commit, state}) {
            const params = {time_type: state.resourceSituationType}
            const {data, level} = await resourceSituation(params)
            if (level === 'success') commit('setResourceSituation', data)
        },


        // 热门会议室前10
        async getResourceHotTenth({commit, state}) {
            const params = {time_type: state.resourceHotTenthType}
            const {data, level} = await resourceHotTenth(params)
            if (level === 'success') commit('setResourceHotTenth', data)
        },


        // 会议室资源预约使用率
        async getResourceUsageRate({commit}) {
            const {data, level} = await resourceUsageRate()
            if (level === 'success') commit('setResourceUsageRate', data)
        },


        // 轮询数据是否有更新
        async eventLoopConferenceUpdate({dispatch}) {
            const {data, level} = await resourcePolling()
            if (level === 'success' && data === '1') {
                dispatch('getResourceData')
                dispatch('getRealTimeDynamic')
                dispatch('getResourceSituation')
                dispatch('getResourceHotTenth')
                dispatch('getResourceUsageRate')
            }
        }

    }

}
