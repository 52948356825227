export default class Auth {

    static TokenKey = "admin_token"

    static getToken = function() {
        return window.localStorage.getItem(Auth.TokenKey)
    }

    static setToken = function(token) {
        return window.localStorage.setItem(Auth.TokenKey, token)
    }

    static removeToken = function() {
        return window.localStorage.removeItem(Auth.TokenKey)
    }

    //判断用户是否登录
    static isLogin = () => {
        const token = Auth.getToken()
        return Boolean(token)
    }

}
